import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ServiceService } from '../../services/service.service';
import { ServiceCategory } from 'src/app/models/service-category.model';
import { Observable, of } from 'rxjs';

export const servicesResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  serviceService: ServiceService = inject(ServiceService),
): Observable<ServiceCategory[]> => {
  const locationUuid = route.paramMap.get('locationUuid');
  // TODO: guard clause for locationUuid + display error message if not present or invalid

  const services = serviceService.getAllServices();
  if (services.length) {
    console.log('ServicesResolverService - return cached services list');
    return of(services);
  } else {
    console.log('ServicesResolverService - do the http call to fetch services list');
    return serviceService.fetchServices(locationUuid);
  }
};
