import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '@services/account.service';

import { Observable, of } from 'rxjs';
import { Account } from 'src/app/models/account.model';

export const accountResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  accountService: AccountService = inject(AccountService),
): Observable<Account> => {
  const locationUuid = route.paramMap.get('locationUuid');
  // TODO: guard clause for locationUuid + display error message if not present or invalid

  const account = accountService.getAccount();
  if (account.id) {
    console.log('accountResolverService - return cached account');
    return of(account);
  } else {
    console.log('accountResolverService - do the http call to fetch account');
    return accountService.fetchAccount(locationUuid);
  }
};
