import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideNzIcons } from './icons-provider';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LoggingInterceptorService } from './interceptors/logging.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http'; // Import the HTTP_INTERCEPTORS token
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { InitThemeService } from '@services/init-theme.service';
import { ThemeSkinService } from '@services/theme-skin.service';
import * as Sentry from '@sentry/angular';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';
// import { provideStore } from '@ngrx/store';
// import { counterReducer } from './store/counter.reducer';
// import { provideEffects } from '@ngrx/effects';
// import { CounterEffects } from './store/counter.effects';

registerLocaleData(en);

export function InitThemeServiceFactory(initThemeService: InitThemeService) {
  return async (): Promise<void> => await initThemeService.initTheme();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideNzIcons(),
    provideNzI18n(en_US),
    importProvidersFrom(FormsModule),
    importProvidersFrom(HttpClientModule),
    provideAnimations(),
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: InitThemeServiceFactory,
      deps: [InitThemeService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (themeService: ThemeSkinService) => () => {
        return themeService.loadTheme();
      },
      deps: [ThemeSkinService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    //provideStore({ counter: counterReducer }),
    //provideEffects([CounterEffects]),
    CustomCurrencyPipe,
    CurrencyPipe,
  ],
};
