import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppointmentService } from '@services/appointment.service';
import { mergeMap, of } from 'rxjs';
import { Appointment } from 'src/app/models/appointment.model';
import { accountResolver } from './account.resolver';

export const appointmentResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  appointmentService: AppointmentService = inject(AppointmentService),
): Promise<Appointment> => {
  return accountResolver(route, state).pipe(
    // TODO: accountResolver is called again here, but it should be called only once; removed accountResolver from app.routes.ts
    mergeMap((accountData) => {
      if (!accountData) return of(null);
      const isTestMode = route.queryParamMap.get('test') === 'true';
      return appointmentService.initAppointment(isTestMode);
    }),
  );
};
